<template>
	<el-dialog
		:title="!dataForm.dailyId ? '新增记事信息' : '修改记事信息'"
		:close-on-click-modal="false"
		:visible.sync="visible"
		width="1000px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px" :inline="true">
			<el-row>
<!--				<el-col :span="12">-->
<!--					<el-form-item label="标的物">-->
<!--						<el-select class="selItemInput" v-model="dataForm.subjectNo" @change="getMajorBySubject()" placeholder="请选择">-->
<!--							<el-option-->
<!--							  v-for="item in subjectList"-->
<!--							  :key="item.displayValue"-->
<!--							  :label="item.displayName"-->
<!--							  :value="item.displayValue">-->
<!--							</el-option>-->
<!--						</el-select>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
				<el-col :span="12">
					<el-form-item label="专业">
						<el-select class="selItemInput" v-model="dataForm.major" placeholder="请选择">
							<el-option
							  v-for="item in majorList"
							  :key="item.displayValue"
							  :label="item.displayName"
							  :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="工作范畴">
						<el-select class="selItemInput" v-model="dataForm.category" placeholder="请选择">
							<el-option
							  v-for="item in categoryList"
							  :key="item.displayValue"
							  :label="item.displayName"
							  :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="记事时间">
						<el-date-picker  class="selItemInput"
						  v-model="dataForm.recTime"
						  type="date"
						  value-format="yyyy-MM-dd"
						  placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="记事人">
						<el-input class="selItemInput" type="input" v-model="dataForm.recMan" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="内容">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="5" v-model="dataForm.recContent" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="照片">
						<div class="addImgBox" v-for="(item,index) in dataForm.list">
						    <el-image
						      style="width: 100%; height: 100%"
						      :src="item.fileUri"
						      fit="cover"></el-image>
							<div class="delBox">
								<i class="el-icon-delete" @click="delImg(index)"></i>
							</div>
						</div>
						<el-upload
						  class="addImgBox"
						  ref="uploadImg"
						  :action="action"
						  :http-request="uploadImg"
						  accept="image/jpeg,image/png,image/jpg">
						  <el-image
						    style="width: 100%; height: 100%"
						    :src="addImg"
						    fit="cover">
						  </el-image>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>
			  <el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			  </el-row>
		</el-form>
	</el-dialog>
</template>

<script>
	import addImg from "@/assets/images/addImg.png"
	import $common from "@/utils/common.js"
	export default {
	  name: "problem-add-or-update",
	  data() {
	    return {
			addImg: addImg,
			id: "",
			visible: true,
			action:"",
			form:{},
			subjectList:[],
			majorList:[],
			categoryList:[],
			dataForm:{
				workNo: $common.getItem("workNo"),
				dailyId:"",
				recMan:"",
				recContent:"",
				subjectNo:"",
				category:"",
				major:"",
				list:[]
			},
			dataRule:{

			}
	    };
	  },
	  components: {

	  },
	  activated() {

	  },
	  methods: {
		init(id) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				dailyId:"",
				recMan:"",
				recContent:"",
				subjectNo:$common.getItem("subjectNo"),
				category:"",
				major:"",
				list:[]
			};
			this.dataForm.dailyId = id?id:0;
			this.getMajorList();
			this.getCategoryList();
			if(id){
				this.getDetail();
			}
		    this.$nextTick(() => {
		        this.visible = true;
		    })
		},
		getCategoryList(){
			this.$http({
			  url:  this.$store.state.httpUrl + "/common/getCategoryList",
			  method: "get",
			  params: {},
			}).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				  this.categoryList = data.body;
			  }
			});
		},
		// getSubjectDropDown(){
		// 	this.$http({
		// 	  url:  this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
		// 	  method: "get",
		// 	  params: {},
		// 	}).then(({ data }) => {
		// 	  if (data && data.resultCode === 200) {
		// 		this.subjectList = data.body;
		// 	  }
		// 	});
		// },
		getMajorList(){
			this.$http({
			  url:  this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				method: "get",
			  params: {},
			}).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				this.majorList = data.body;
			  }
			});
		},
		delImg(index){
			let _list = this.dataForm.list;
			_list.splice(index,1);
			this.dataForm.list = _list;
		},
		uploadImg(param){
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/"+$common.getItem("workNo")+"/10/0",
				formData,
				{
				  headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
			  res = res.data
			  if (res.resultCode == 200){
				this.dataForm.list.push({
					photoName: res.body.fileName,
					photoPath: res.body.fileSavePath,
					fileUri: res.body.fileUri
				});
			  } else {
				this.$message.info('文件上传失败')
			  }
			})
		},
		getDetail(){
			this.$http({
			  url: this.$store.state.httpUrl + "/business/dailyrecord/info/"+this.dataForm.dailyId,
			  method: "get",
			  params: {},
			}).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				this.dataForm = data.body
			  }
			});
		},
		dataFormSubmit() {
		  this.$refs["dataForm"].validate(valid => {
		    if (valid) {
			  let _url = "/business/dailyrecord/save";
			  if(this.dataForm.dailyId && this.dataForm.dailyId != 0){
				_url = "/business/dailyrecord/update";
			  }
		      this.$http({
		        url: this.$store.state.httpUrl + _url,
		        method: "post",
		        data: this.$http.adornData(this.dataForm)
		      }).then(({ data }) => {
		        if (data && data.resultCode === 200) {
		          this.$message({
		            message: "操作成功",
		            type: "success",
		            duration: 1500,
		            onClose: () => {
		              this.visible = false;
		              this.$emit("refreshDataList");
		            }
		          });
		        } else {
		          this.$message.error(data.msg);
		        }
		      });
		    }
		  });
		}
	  }
	};
</script>

<style lang="scss" scoped="scoped">
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
	.addImgBox{
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
</style>
