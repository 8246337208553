<template>
	<!-- 记事信息 -->
  <div class="mod-config">
	<el-card body-style="padding-bottom: 0;">
		<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
			<el-row>
				<el-col :span="20">
					<el-form-item label="记事时间">
						<el-date-picker
						  class="selItemInput"
						  v-model="recContentTime"
						  value-format="yyyy-MM-dd"
						  type="daterange"
						  range-separator="至"
						  start-placeholder="开始日期"
						  end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="4" class="selBtnGroup">
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="resetFormData()">重置</el-button>
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="selGetDataList()" v-if="showSearch">检索</el-button>
				</el-col>
			</el-row>
		</el-form>
	</el-card>
	<el-row style="margin-top: 20px;">
		<el-col :span="pageSpan" v-if="showAdd">
			<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-circle-plus-outline" @click="addOrUpdateHandle()">添加</el-button>
		</el-col>
		<el-col :span="pageSpan" style="text-align: right;">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :pager-count="5"
			  @current-change="currentChangeHandle"
			  :current-page="page"
			  :page-size="limit"
			  :total="totalCount">
			</el-pagination>
		</el-col>
	</el-row>
	<el-row :gutter="20">
		<el-col :span="8" style="margin-top: 20px;" v-for="(item,index) in dataList">
			<el-card>
				<el-row>
					<el-col :span="12">
						<span style="color: #00428e; margin-right: 10px; font-weight: bold;">记事时间</span>
						<span style="color: #727580;">{{item.recTime}}</span>
					</el-col>
					<el-col :span="12">
						<span style="color: #00428e; margin-right: 10px; font-weight: bold;">记录人</span>
						<span style="color: #727580;">{{item.recMan}}</span>
					</el-col>
					<el-col :span="24">
						<div class="noteTitle">内容</div>
						<div class="noteContent" style="height: 60px; line-height: 30px; overflow: hidden;">
							{{item.recContent}}
						</div>
						<div style="text-align: right; margin-top: 20px;" v-if="showDelete || showEdit">
							<el-button v-preventReClick class="primaryPlainBtn" type="primary" size="mini" plain icon="el-icon-edit-outline" @click="addOrUpdateHandle(item.dailyId)" v-if="showEdit">修改</el-button>
							<el-button v-preventReClick class="primaryPlainBtn" type="primary" size="mini" plain icon="el-icon-delete" @click="deleteHandle(item.dailyId)" v-if="showDelete">删除</el-button>
						</div>
					</el-col>
				</el-row>
			</el-card>
		</el-col>
	</el-row>
	<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>
  </div>
</template>
<script>
	import AddOrUpdate from './noteList-add-or-update'
	import $common from "@/utils/common.js"
	export default {
	  data() {
		return {
			showSearch: false,
			showAdd: false,
			showEdit: false,
			showDelete: false,
			pageSpan: 12,
			form:{},
			// subjectList:[],
			dataList:[],
			recContentTime:[],
			// subjectNo:"",
			limit: 6,
			page: 1,
			totalCount:0,
		    addOrUpdateVisible: false,
		};
	  },
	  components: {
		  AddOrUpdate
	  },
	  mounted() {
		// this.getSubjectDropDown();
		  this.showSearch = $common.isAuth('noteList:search');
		  this.showAdd = $common.isAuth('noteList:add');
		  this.showEdit = $common.isAuth('noteList:edit');
		  this.showDelete = $common.isAuth('noteList:delete');
		  if (!this.showAdd){
			  this.pageSpan = 24;
		  }
	  	this.getDataList();
	  },
	  methods: {
		// getSubjectDropDown(){
		// 	this.$http({
		// 	  url:  this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
		// 	  method: "get",
		// 	  params: {},
		// 	}).then(({ data }) => {
		// 	  if (data && data.resultCode === 200) {
		// 		this.subjectList = data.body;
		// 	  }
		// 	});
		// },
		selGetDataList(){
			this.page = 1;
			this.getDataList();
		},
		resetFormData(){
			this.subjectNo = "";
			this.recContentTime = [];
		},
		currentChangeHandle(val) {
			this.page = val
			this.getDataList()
		},
		getDataList(){
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/dailyrecord/list",
					method: "get",
					params: {
						limit: this.limit,
						page: this.page,
						recContentBegin: this.recContentTime[0] ? this.recContentTime[0] : '',
						recContentEnd: this.recContentTime[1] ? this.recContentTime[1] : '',
						subjectNo: $common.getItem("subjectNo"),
						workNo: $common.getItem("workNo")
					},
				}).then(({data}) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalCount = data.body.totalCount;
					}
				});
			}
		},
		addOrUpdateHandle(id){
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
			  this.$refs.addOrUpdate.init(id)
			})
		},
		deleteHandle (id) {
			this.$confirm(`确定对[id=${id}]进行删除操作?`, '提示', {
		        confirmButtonText: '确定',
		        cancelButtonText: '取消',
		        type: 'warning'
		    }).then(() => {
		        this.$http({
					url:  this.$store.state.httpUrl + "/business/dailyrecord/delete",
		            method: 'post',
		            data: [id]
		        }).then(({data}) => {
		            if (data && data.resultCode === 200) {
		                this.$message({
		                    message: '操作成功',
		                    type: 'success',
		                    duration: 1500,
		                    onClose: () => {
		                        this.getDataList()
		                    }
		                })
		            } else {
		                this.$message.error(data.msg)
		            }
		        })
		    })
		},
	  }
	};
</script>
<style lang="scss" scoped="scoped">
	.noteTitle{
		font-weight: bold;
		color: #00428e;
		border-bottom: 1px solid #00428e;
		padding-bottom: 10px;
		margin-top: 20px;
	}
	.noteContent{
		color: #727580;
		margin-top: 10px;
		line-height: 25px;
	}
</style>
scope
